import {
  type BaseRecord,
  type HttpError,
  useCreate,
  useInvalidate,
} from '@refinedev/core';
import type { SubmitHandler, UseFormSetError } from 'react-hook-form';
import type { ToSuccessFormValues } from 'pages/types';
import type { Attachment, OrderDetail } from 'types';
import {
  transformSuccessPayload,
  validateTotalCarriedQuantity,
} from '../helpers';
import { geoRemoveLocationFromStorage } from 'hooks/useGeoLocation/helper';

export const usePickupOnDelivery = (
  deliveryDetail: OrderDetail,
  setError: UseFormSetError<ToSuccessFormValues>,
  isEnableGeoLocation?: boolean,
) => {
  const { mutate, isLoading } = useCreate<BaseRecord, HttpError>();
  const { mutate: generateRR, isLoading: isGenerateRRLoading } = useCreate<
    BaseRecord & Attachment,
    HttpError
  >();

  const invalidate = useInvalidate();

  const updateToNextStatus: SubmitHandler<ToSuccessFormValues> = formValues => {
    const isInvalid = validateTotalCarriedQuantity(
      formValues,
      setError,
      deliveryDetail.business_type,
    );
    if (!isInvalid) {
      mutate(
        {
          resource: `driver/shipping-order/${deliveryDetail.id}/status/delivery-success`,
          values: transformSuccessPayload(
            formValues,
            deliveryDetail.id,
            isEnableGeoLocation,
          ),
          successNotification: {
            type: 'success',
            message: 'Berhasil update status order',
            description: `Status order ${deliveryDetail.so_number} menjadi Selesai`,
          },
        },
        {
          onSuccess: () => {
            if (isEnableGeoLocation) {
              geoRemoveLocationFromStorage(deliveryDetail?.id);
            }
            invalidate({
              resource: `driver/shipping-order`,
              invalidates: ['all'],
            });
          },
        },
      );
    }
  };

  return {
    isLoading,
    updateToNextStatus,
    generateRR,
    isGenerateRRLoading,
  };
};
