import type { OrderStatus, PaymentType, RejectedReasonType } from 'types';
import type { DamageState, SkipPaymentRemarkType } from '../../../pages/types';

export const PAGE_SIZE = 4;

export const DELIVERY_OPTIONS: {
  [K in OrderStatus]: {
    label: string;
    colorScheme: string;
    nextStatus: OrderStatus;
  };
} = {
  READY_TO_ORIGIN: {
    label: '',
    colorScheme: 'grey',
    nextStatus: 'AT_ORIGIN',
  },
  AT_ORIGIN: {
    label: 'Muat Barang',
    colorScheme: 'yellow',
    nextStatus: 'INTRANSIT',
  },
  INTRANSIT: {
    label: 'Lanjut Kirim Barang',
    colorScheme: 'orange',
    nextStatus: 'PAYMENT',
  },
  PAYMENT: {
    label: 'Proses Pembayaran',
    colorScheme: 'green',
    nextStatus: 'WAITING_FOR_PAYMENT',
  },
  WAITING_FOR_PAYMENT: {
    label: 'Konfirmasi Pembayaran',
    colorScheme: 'cyan',
    nextStatus: 'DELIVERY_SUCCESS',
  },
  DELIVERY_SUCCESS: {
    label: 'Selesaikan Pengiriman',
    colorScheme: 'observatory',
    nextStatus: 'DELIVERY_SUCCESS',
  },
};

export const FISH_CONDITIONS: {
  label: string;
  value: DamageState;
  colorScheme: string;
}[] = [
  {
    label: 'Ya, aman',
    value: 'NONE',
    colorScheme: 'brand',
  },
  {
    label: 'Tidak, ada mati',
    value: 'PARTIAL',
    colorScheme: 'yellow',
  },
  {
    label: 'Mati semua',
    value: 'ALL',
    colorScheme: 'red',
  },
];

export const REJECT_REASONS: {
  label: string;
  value: RejectedReasonType;
}[] = [
  {
    label: 'Cacat',
    value: 'DEFECT_DEFORMED',
  },
  {
    label: 'Susut',
    value: 'DEFECT_SHRINK',
  },
  {
    label: 'Lainnya',
    value: 'DEFECT_OTHER',
  },
];

export const DEFAULT_REJECTED_REASON_TYPE: Record<
  Exclude<DamageState, 'NONE'>,
  RejectedReasonType
> = {
  PARTIAL: 'PARTIALLY_DAMAGED',
  ALL: 'ALL_DAMAGED',
};

export const DEFAULT_REJECTED_REASON_TYPE_V1: Record<
  Exclude<DamageState, 'NONE'>,
  RejectedReasonType
> = {
  PARTIAL: 'DEFECT_PARTIALLY_DEAD',
  ALL: 'DEFECT_ALL_DEAD',
};

export const CANCEL_REASONS: {
  label: string;
  value: RejectedReasonType;
}[] = [
  {
    label: 'Customer Tidak di Tempat',
    value: 'CANCEL_CUSTOMER_NOT_PRESENT',
  },
  {
    label: 'Customer Tidak Merasa Order',
    value: 'CANCEL_NOT_ORDER',
  },
  {
    label: 'Komoditas tidak sesuai',
    value: 'CANCEL_MISMATCHED_COMMODITY',
  },
  {
    label: 'Size tidak sesuai',
    value: 'CANCEL_INCORRECT_SIZE',
  },
  {
    label: 'Kualitas tidak sesuai',
    value: 'CANCEL_MISMATCHED_QUALITY',
  },
  {
    label: 'Salah Input Order dari BD',
    value: 'CANCEL_INCORRECT_ORDER_INPUT_FROM_BD',
  },
  {
    label: 'Telat Pengiriman',
    value: 'CANCEL_LATE_DELIVERY',
  },
  {
    label: 'Kejadian Tidak Terduga (Force Majeur)',
    value: 'CANCEL_FORCE_MAJEURE',
  },
  {
    label: 'Lainnya',
    value: 'CANCEL_OTHER',
  },
];

export const PAYMENT_TYPE_LABEL: {
  [K in PaymentType]: string;
} = {
  HARD_CASH: 'Hard Cash',
  CASH_MANUAL_TRANSFER: 'Cash Manual Transfer',
  CASH_VA: 'Cash With Virtual Account',
  TERMS: 'Terms',
};

export const SKIP_PAYMENT_REMARK_TYPE_LABEL: {
  [K in SkipPaymentRemarkType]: string;
} = {
  SKIP_INVOICE_NOT_EXIST: 'Invoice tidak tersedia',
  SKIP_PAYMENT_LINK_NOT_EXIST: 'Payment Link tidak tersedia',
  SKIP_PAYMENT_TYPE_TERM: 'Order Tempo',
  SKIP_OTHER: 'Lainnya',
};

export const FISH_INTERNAL_ERROR =
  'FISH-INTERNAL-TOOL-ERR-01 - unexpected error from fish_internal_tools service';
export const ORDER_AGGREGATOR_ERROR =
  'ORDER_AGGREGATOR_SERVICE_ERROR_4XX - error from Order Aggregator with status 4xx';
