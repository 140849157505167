import { Box } from '@efishery/onefish';
import { HttpError, useOne, useParsed } from '@refinedev/core';
import { Empty, Loading } from 'components';
import { FLAG_ID } from 'configs';
import { useFlag } from 'hooks';
import { useCallback } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import type { OrderDetail } from 'types';
import {
  DeliveryDraft,
  DeliveryLoading,
  OnDelivery,
  OnInvoicePayment,
  WaitingForPayment,
} from './components';
import {
  GeoLocationContext,
  IrregularityEnumContext,
  ShrimpDownstreamContext,
} from 'contexts';
import { flag } from '@onefish/flag';

export const DeliveryEdit = () => {
  const { id } = useParsed();
  const [searchParams] = useSearchParams();

  const { data, isLoading, isError, refetch } = useOne<OrderDetail, HttpError>({
    resource: 'driver/shipping-order',
    id,
  });

  const order = data?.data;

  const isOn = useFlag(FLAG_ID.ENABLE_FEAT_HARD_CASH_PAYMENT);
  const isFeatShrimpDownstream = useFlag(FLAG_ID.ENABLE_FEAT_SHRIMP_DOWNSTREAM);
  const isNewIrregularityEnumEnabled = useFlag(
    FLAG_ID.ENABLE_NEW_IRREGULARITY_ENUM,
  );
  const isEnableGeoLocation = useFlag(FLAG_ID.ENABLE_FEAT_GEOLOCATION_FLAG_ID);

  const OnInvoicePaymentWrapped = useCallback(() => {
    return (
      <ShrimpDownstreamContext.Provider value={isFeatShrimpDownstream}>
        <GeoLocationContext.Provider value={isEnableGeoLocation}>
          <OnInvoicePayment
            deliveryDetail={order}
            refetch={refetch}
            isEnableFeatHardCash={isOn}
          />
        </GeoLocationContext.Provider>
      </ShrimpDownstreamContext.Provider>
    );
  }, [isFeatShrimpDownstream, isEnableGeoLocation, order, refetch, isOn]);

  const OnDeliveryWrapped = useCallback(() => {
    if (order) {
      return (
        <ShrimpDownstreamContext.Provider value={isFeatShrimpDownstream}>
          <IrregularityEnumContext.Provider
            value={isNewIrregularityEnumEnabled}
          >
            <OnDelivery
              deliveryDetail={order}
              isEnableGeoLocation={isEnableGeoLocation}
            />
          </IrregularityEnumContext.Provider>
        </ShrimpDownstreamContext.Provider>
      );
    }
  }, [
    isEnableGeoLocation,
    isFeatShrimpDownstream,
    isNewIrregularityEnumEnabled,
    order,
  ]);

  const WaitingForPaymentWrapped = useCallback(() => {
    if (order) {
      return (
        <GeoLocationContext.Provider value={isEnableGeoLocation}>
          <WaitingForPayment deliveryDetail={order} />
        </GeoLocationContext.Provider>
      );
    }
  }, [isEnableGeoLocation, order]);

  const DeliveryLoadingWrapped = useCallback(() => {
    if (order) {
      return (
        <DeliveryLoading
          deliveryDetail={order}
          isEnableGeoLocation={isEnableGeoLocation}
        />
      );
    }
  }, [order, isEnableGeoLocation]);

  if (isLoading) return <Loading />;
  if (isError || !order) return <Empty />;

  let rendered = null;
  const destUrl = `/finish/show/${order.id}`;

  switch (order.status) {
    case 'READY_TO_ORIGIN':
      rendered = <DeliveryDraft deliveryDetail={order} />;
      break;
    case 'AT_ORIGIN':
      rendered = <DeliveryLoadingWrapped />;
      break;
    case 'INTRANSIT':
      rendered = <OnDeliveryWrapped />;
      break;
    case 'PAYMENT':
      rendered = <OnInvoicePaymentWrapped />;
      break;
    case 'WAITING_FOR_PAYMENT':
      if (searchParams.get('change_payment') === 'true') {
        rendered = <OnInvoicePaymentWrapped />;
      } else {
        rendered = <WaitingForPaymentWrapped />;
      }
      break;
    case 'DELIVERY_SUCCESS':
      rendered = (
        <Navigate
          to={destUrl}
          state={{
            finish: true,
          }}
        />
      );
      break;
    default:
      rendered = <Navigate to={`/delivery`} />;
      break;
  }

  return flag(
    FLAG_ID.ENABLE_DELIVERY,
    <Box px="3">{rendered}</Box>,
    <Empty message="Saat ini fitur tidak tersedia" />,
  );
};
