import dayjs from 'dayjs';

import { BusinessType, Order, OrderDetail, OrderStatus } from 'types';
import { formatDateTimeRange, toTitleCase } from 'utils';

import type {
  ToSuccessFormValues,
  ToSuccessTransformedFormValues,
} from '../types';
import { PICKUP_OPTIONS } from './constants';
import { ToIntransitFormValues } from 'pages/types';
import { UseFormSetError } from 'react-hook-form';
import { comodityDefectWord, comodityFineWord } from 'features/transport/utils';
import { geoFetchLocationFromStorage } from 'hooks/useGeoLocation/helper';

type MessageOption = {
  showAddress?: boolean;
};

/**
 *  Convert eta date from API response with UTC time to local time
 *  The time returned can be used for displaying eta date in UI or for payload data
 * @param eta_date_start
 * @param eta_date
 * @param date_format required - default to D MMM YYYY
 * @param time_format required - default to D MMM YYYY
 * @returns
 */
export const formatEta = (
  eta_date_start?: string,
  eta_date?: string,
  date_format = 'D MMM YYYY',
  time_format = 'HH:mm',
) => {
  const etaDate = eta_date
    ? dayjs.utc(eta_date).local().format(date_format)
    : '';

  const etaTimeStart = eta_date_start
    ? dayjs.utc(eta_date_start).local().format(time_format)
    : '';

  const etaTimeEnd = eta_date
    ? dayjs.utc(eta_date).local().format(time_format)
    : '';

  return {
    etaDate,
    etaTimeStart,
    etaTimeEnd,
  };
};

export const formatPickupStatus = (status: OrderStatus) => {
  const pickupStatus = PICKUP_OPTIONS[status];

  return {
    buttonLabel: PICKUP_OPTIONS[pickupStatus.nextStatus].label,
    statusColorScheme: pickupStatus.colorScheme,
    statusLabel: pickupStatus.label,
  };
};

export const composePickupMessage = (
  order: Order | OrderDetail,
  { showAddress = false }: MessageOption = {},
) => {
  const { dest_name, expected_delivery_at, expected_delivery_end_at, status } =
    order;

  const subdistrict = toTitleCase(order?.dest_subdistrict_name ?? '');
  const district = toTitleCase(order?.dest_district_name ?? '');
  const city = toTitleCase(order?.dest_city_name ?? '');

  const address = `${order.dest_address} ${subdistrict}, ${district}, ${city}`;

  switch (status) {
    case 'READY_TO_ORIGIN':
    case 'AT_ORIGIN':
      return (
        <>
          Untuk pengiriman ke <strong>{dest_name}</strong>
          <br />
          {showAddress && (
            <>
              {address}
              <br />
            </>
          )}
          Sampai di Hub{' '}
          <em>
            tanggal{' '}
            {formatDateTimeRange(
              expected_delivery_at ?? '',
              expected_delivery_end_at ?? '',
            )}
          </em>
        </>
      );
    case 'INTRANSIT':
    case 'DELIVERY_SUCCESS':
      return (
        <>
          {showAddress && (
            <>
              {address}
              <br />
            </>
          )}
          Sampai di Hub{' '}
          <em>
            tanggal{' '}
            {formatDateTimeRange(
              expected_delivery_at ?? '',
              expected_delivery_end_at ?? '',
            )}
          </em>
        </>
      );
  }
};

export const getOnDeliveryDefaultValues = (
  deliveryDetail: OrderDetail,
): ToIntransitFormValues => {
  return {
    attachments: [{ link: '', type: 'RECEIVING_REPORT' }],
    order_lines: deliveryDetail.order_lines.map(orderLine => ({
      id: orderLine.id,
      receive_good_quantity: undefined,
      receive_damaged_quantity: undefined,
      final_good_product_price: orderLine.original_price,
      final_damaged_product_price: 0,
      rejected_quantity: 0,
      rejected_reason_detail: '',
      rejected_reason_type: '',
      damaged_quantity: 0,
      carried_quantity: orderLine.carried_quantity,
      damage_state: undefined,
    })),
  };
};

export const validateTotalCarriedQuantity = (
  formValues: ToSuccessFormValues,
  setError: UseFormSetError<ToSuccessFormValues>,
  businessType?: BusinessType,
) => {
  let isInvalid = false;

  formValues.order_lines.forEach((orderLine, index) => {
    if (
      orderLine.receive_good_quantity + orderLine.receive_damaged_quantity >
      orderLine.carried_quantity
    ) {
      setError(`order_lines.${index}.receive_good_quantity`, {
        type: 'manual',
        message:
          'Total jumlah ' +
          comodityFineWord(businessType) +
          ' dan ' +
          comodityDefectWord(businessType) +
          ' melebihi jumlah dibawa',
      });
      setError(`order_lines.${index}.receive_damaged_quantity`, {
        type: 'manual',
        message:
          'Total jumlah ' +
          comodityFineWord(businessType) +
          ' dan ' +
          comodityDefectWord(businessType) +
          ' melebihi jumlah dibawa',
      });
      isInvalid = true;
    }
  });

  return isInvalid;
};

export const transformSuccessPayload = (
  formValues: ToSuccessFormValues,
  deliveryId?: string,
  isEnableGeoLocation?: boolean,
): ToSuccessTransformedFormValues => {
  const attachments = formValues.attachments?.filter(
    attachment => attachment.link,
  );
  const orderLines = formValues.order_lines.map(orderLine => {
    return {
      id: orderLine.id,
      receive_good_quantity: orderLine.receive_good_quantity,
      receive_damaged_quantity: orderLine.receive_damaged_quantity,
      final_good_product_price: orderLine.final_good_product_price,
      final_damaged_product_price: orderLine.final_damaged_product_price,
      rejected_quantity: 0,
      rejected_reason_type: orderLine.rejected_reason_type,
      rejected_reason_detail: orderLine.rejected_reason_detail,
    };
  });

  const payload = { attachments, order_lines: orderLines };

  if (isEnableGeoLocation) {
    const coords = geoFetchLocationFromStorage(deliveryId);
    return {
      ...payload,
      latitude: coords?.lat ?? null,
      longitude: coords?.lng ?? null,
    };
  }

  return payload;
};
