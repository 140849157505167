import { HStack, useRadioGroup } from '@chakra-ui/react';
import { FISH_CONDITIONS } from '../../../features/transport/constants';
import { RadioCard } from './RadioCard';
import { downstreamComodityConditions } from 'utils/comodity';
import { BusinessType } from 'types';

type Props = {
  isFeatShrimpDownstream: boolean;
  index: number;
  getRadioProps: ReturnType<typeof useRadioGroup>['getRadioProps'];
  businessType?: BusinessType;
};

export const FishConditionRadio = ({
  isFeatShrimpDownstream,
  index,
  getRadioProps,
  businessType,
}: Props) => {
  if (isFeatShrimpDownstream) {
    return (
      <HStack justifyContent="space-between">
        {downstreamComodityConditions(businessType ?? 'FISH_DOWNSTREAM').map(
          condition => {
            const radio = getRadioProps({ value: condition.value });
            return (
              <RadioCard
                key={`${condition.value}-${index}`}
                id={condition.value}
                value={condition.value}
                index={index}
                {...radio}
              >
                {condition.label}
              </RadioCard>
            );
          },
        )}
      </HStack>
    );
  }

  return (
    <HStack justifyContent="space-between">
      {FISH_CONDITIONS.map(condition => {
        const radio = getRadioProps({ value: condition.value });
        return (
          <RadioCard
            key={`${condition.value}-${index}`}
            id={condition.value}
            value={condition.value}
            index={index}
            {...radio}
          >
            {condition.label}
          </RadioCard>
        );
      })}
    </HStack>
  );
};
