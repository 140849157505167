import { Box } from '@efishery/onefish';
import { HttpError, useOne, useParsed } from '@refinedev/core';

import { Empty, Loading } from 'components';
import type { OrderDetail } from 'types';

import { FLAG_ID } from 'configs';
import { ShrimpDownstreamContext } from 'contexts';
import { useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { PickupDraft, PickupLoading, PickupOnDelivery } from './components';
import { useFlag } from 'hooks';

export const PickupEdit = () => {
  const { id } = useParsed();

  const { data, isLoading, isError } = useOne<OrderDetail, HttpError>({
    resource: 'driver/shipping-order',
    id,
  });

  const order = data?.data;

  const isEnableGeoLocation = useFlag(
    FLAG_ID.ENABLE_FEAT_GEOLOCATION_FLAG_ID,
  );
  const isFeatShrimpDownstream = useFlag(
    FLAG_ID.ENABLE_FEAT_SHRIMP_DOWNSTREAM,
  );

  const PickupLoadingWrapped = useCallback(() => {
    if (order) {
      return (
        <ShrimpDownstreamContext.Provider value={isFeatShrimpDownstream}>
          <PickupLoading
            deliveryDetail={order}
            isEnableGeoLocation={isEnableGeoLocation}
          />
        </ShrimpDownstreamContext.Provider>
      );
    }
  }, [order, isFeatShrimpDownstream, isEnableGeoLocation]);
  // end of development flag featShrimpDownstream

  // development flag feat EnableGeoLocation
  const PickupOnDeliveryWrapped = useCallback(() => {
    if (order) {
      return (
        <PickupOnDelivery
          deliveryDetail={order}
          isEnableGeoLocation={isEnableGeoLocation}
        />
      );
    }
  }, [order, isEnableGeoLocation]);
  // end of development flag feat EnableGeoLocation

  if (isLoading) return <Loading />;
  if (isError || !order) return <Empty />;

  let rendered = null;

  switch (order.status) {
    case 'READY_TO_ORIGIN':
      rendered = <PickupDraft deliveryDetail={order} />;
      break;
    case 'AT_ORIGIN':
      rendered = <PickupLoadingWrapped />;
      break;
    case 'INTRANSIT':
      rendered = <PickupOnDeliveryWrapped />;
      break;
    case 'DELIVERY_SUCCESS':
      rendered = (
        <Navigate
          to={`/finish/show/${order.id}`}
          state={{
            finish: true,
          }}
        />
      );
      break;
    default:
      rendered = <Navigate to={`/pickup`} />;
      break;
  }

  return <Box px="3">{rendered}</Box>;
};
