/* eslint-disable @typescript-eslint/no-explicit-any */
import { ANALYTIC } from 'configs';
import TagManager from 'react-gtm-module';

export const initializeGtm = () => {
  const tagManagerArgs = {
    gtmId: ANALYTIC.GTM_ID,
  };
  return TagManager.initialize(tagManagerArgs);
};

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

export const trackEvent = (eventName: string, eventProperties: any) => {
  if (window) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventName,
      ...eventProperties,
    });
  }
};

export const trackSignOut = (phone_number: string) => {
  trackEvent('Click-Profile-Signout', { phone_number });
};

export type DataLayerVariables = {
  phone_number?: string;
  delivery_id?: string;
};

type ReceivingReportEvent = 'confirm_receiving' | 'download_receipt';

export const trackReceivingReport = (
  event: ReceivingReportEvent,
  eventProperties?: DataLayerVariables,
) => {
  if (event === 'confirm_receiving') {
    trackEvent('Click-Confirm-Receiving', eventProperties);
    return;
  }

  if (event === 'download_receipt') {
    trackEvent('Click-Confirm-Receiving', eventProperties);
    return;
  }
};

type VirtualAccountEvent =
  | 'proses_pembayaran'
  | 'cancel_delivery'
  | 'menuju_pembayaran'
  | 'konfirmasi_pembayaran'
  | 're_sync_pembayaran';
export const trackVirtualAccountPayment = (
  event: VirtualAccountEvent,
  eventProperties?: DataLayerVariables,
) => {
  if (event === 'proses_pembayaran') {
    trackEvent('Click-Proses-Pembayaran', eventProperties);
    return;
  }
  if (event === 'cancel_delivery') {
    trackEvent('Click-Cancel-Delivery', eventProperties);
    return;
  }
  if (event === 'menuju_pembayaran') {
    trackEvent('Click-Menuju-Pembayaran', eventProperties);
    return;
  }
  if (event === 'konfirmasi_pembayaran') {
    trackEvent('Click-Konfirmasi-Pembayaran', eventProperties);
    return;
  }
};
