import { SyncStatus } from 'pages/delivery/components/OnInvoicePayment/types';
import { PAYMENT_TYPE_LABEL } from 'features/transport/constants';
import { BusinessType, PaymentType } from 'types';

export const MetodePembayaranOpts: {
  value: PaymentType;
  label: string;
  ['data-testid']: string;
}[] = [
  {
    value: 'HARD_CASH',
    label: PAYMENT_TYPE_LABEL['HARD_CASH'],
    ['data-testid']: 'dd_opt_hard_cash',
  },
  {
    value: 'CASH_VA',
    label: PAYMENT_TYPE_LABEL['CASH_VA'],
    ['data-testid']: 'dd_opt_cash_va',
  },
];

export const isAbleToSelectPayment = (
  paymentValue: PaymentType,
  businessType: BusinessType,
  syncStatus?: SyncStatus,
  isEnableFeatHardCash?: boolean,
  isEnableFeatShrimpDownstream?: boolean,
) => {
  // development flag shrimp downstream
  if (isEnableFeatShrimpDownstream && businessType === 'SHRIMP_DOWNSTREAM') {
    return false;
  }

  // development flag hard cash
  if (isEnableFeatHardCash && paymentValue === 'HARD_CASH') {
    return true;
  }

  if (syncStatus === 'success') {
    return true;
  }

  return false;
};
