import { Auth, Stage } from '@efishery/sdk-core';
import { AuthBindings } from '@refinedev/core';
import { captureException } from '@sentry/react';
import { ENV } from 'configs';
import nookies from 'nookies';
import { CustomProfileResponse } from 'types';
import { sentry } from 'utils';

export const auth = new Auth(
  ENV.CLIENT_ID,
  Stage[ENV.STAGE as keyof typeof Stage],
);

export const authProvider: AuthBindings = {
  login: async ({ grantType, code }) => {
    try {
      const { accessToken } = await auth.persistExchange(code, grantType);
      await auth.getProfile(accessToken);

      return {
        success: true,
        redirectTo: '/',
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.code === 'ERR_BAD_REQUEST') {
        const msg = error?.response?.data || '-';
        captureException(msg, {
          tags: {
            'driver-app': 'auth-provider-error-login',
          },
        });
      }
      return {
        success: false,
        redirectTo: '/',
      };
    }
  },
  logout: async () => {
    try {
      await auth.logout();
    } catch (error) {
      captureException(error, {
        tags: {
          'driver-app': 'auth-provider-error-logout',
        },
      });

      return {
        success: true,
        redirectTo: '/login',
      };
    }
    return {
      success: true,
      redirectTo: '/login',
    };
  },
  check: async ctx => {
    const accessTokenKey = auth.getAccessTokenKey();
    const cookies = nookies.get(ctx);
    if (!cookies[accessTokenKey]) {
      return {
        authenticated: false,
        redirectTo: '/login',
      };
    }

    return {
      authenticated: true,
    };
  },
  getPermissions: async () => null,
  getIdentity: async ctx => {
    const accessTokenKey = auth.getAccessTokenKey();
    const cookies = nookies.get(ctx);

    // should only fetch profile if access token in cookie exists
    if (accessTokenKey in cookies) {
      const profile = await auth.getProfile(cookies[accessTokenKey]);

      const profileData = profile?.data as unknown as CustomProfileResponse;

      if (profileData?.data) {
        sentry.setUser(profileData?.data);
      }
      return profileData?.data ?? null;
    }
    return null;
  },
  onError: async error => {
    return { error };
  },
};
