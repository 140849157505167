import type { OrderStatus, Order, OrderDetail } from 'types';
import type {
  DamageState,
  OrderLine,
  ToSuccessFormValues,
  ToSuccessTransformedFormValues,
} from '../types';
import { formatDateTimeRange, toTitleCase } from 'utils';
import {
  DEFAULT_REJECTED_REASON_TYPE,
  DEFAULT_REJECTED_REASON_TYPE_V1,
  DELIVERY_OPTIONS,
  FISH_INTERNAL_ERROR,
  ORDER_AGGREGATOR_ERROR,
} from '../../features/transport/constants';
import type { HttpError, OpenNotificationParams } from '@refinedev/core';
import { geoFetchLocationFromStorage } from 'hooks/useGeoLocation/helper';

type MessageOption = {
  showAddress?: boolean;
};

export const composeDeliveryMessage = (
  delivery: Order | OrderDetail,
  { showAddress = false }: MessageOption = {},
) => {
  const { dest_name, expected_delivery_at, expected_delivery_end_at, status } =
    delivery;

  const subdistrict = toTitleCase(delivery?.dest_subdistrict_name ?? '');
  const district = toTitleCase(delivery?.dest_district_name ?? '');
  const city = toTitleCase(delivery?.dest_city_name ?? '');

  const address = `${delivery.dest_address} ${subdistrict}, ${district}, ${city}`;

  switch (status) {
    case 'READY_TO_ORIGIN':
      return (
        <>
          Untuk pengiriman ke <strong>{dest_name}</strong>
          <br />
          {showAddress && (
            <>
              {address}
              <br />
            </>
          )}
          Sampai di Buyer{' '}
          <em>
            tanggal{' '}
            {formatDateTimeRange(
              expected_delivery_at ?? '',
              expected_delivery_end_at ?? '',
            )}
          </em>
        </>
      );
    case 'AT_ORIGIN':
    case 'INTRANSIT':
    case 'PAYMENT':
    case 'WAITING_FOR_PAYMENT':
    case 'DELIVERY_SUCCESS':
      return (
        <>
          {showAddress && (
            <>
              {address}
              <br />
            </>
          )}
          Sampai di Buyer{' '}
          <em>
            tanggal{' '}
            {formatDateTimeRange(
              expected_delivery_at ?? '',
              expected_delivery_end_at ?? '',
            )}
          </em>
        </>
      );
  }
};

export const formatDeliveryStatus = (status: OrderStatus) => {
  const deliveryStatus = DELIVERY_OPTIONS[status];

  return {
    buttonLabel: DELIVERY_OPTIONS[deliveryStatus.nextStatus].label,
    statusColorScheme: deliveryStatus.colorScheme,
    statusLabel: deliveryStatus.label,
  };
};

export function calcTotalPrice(quantity: number, price: number) {
  return quantity * price;
}

export const getOnDeliveryDefaultValues = (
  deliveryDetail: OrderDetail,
): ToSuccessFormValues => {
  return {
    order_lines: deliveryDetail.order_lines.map(orderLine => {
      return {
        id: orderLine.id,
        receive_good_quantity: orderLine.carried_quantity,
        receive_damaged_quantity: 0,
        final_good_product_price: orderLine.original_price,
        final_damaged_product_price: orderLine.original_price / 2 + 5000,
        damaged_quantity: 0,
        carried_quantity: orderLine.carried_quantity,
        damage_state: undefined,
        rejected_quantity: 0,
        rejected_reason_detail: '',
        rejected_reason_type: '',
      };
    }),
    attachments: [
      {
        link: '',
        type: 'RECEIVING_REPORT',
      },
      {
        link: '',
        type: 'PRODUCT_CONDITION',
      },
      {
        link: '',
        type: 'BUYER_ACCEPTANCE',
      },
      {
        link: '',
        type: 'PROOF_OF_PAYMENT',
      },
    ],
  };
};

export const generateRejectedReasonType = (
  orderLine: OrderLine,
  isNewIrregularityEnumEnabled?: boolean,
) => {
  if (isNewIrregularityEnumEnabled) {
    return orderLine.damage_state !== 'NONE'
      ? DEFAULT_REJECTED_REASON_TYPE_V1[
          orderLine.damage_state as Exclude<DamageState, 'NONE'>
        ]
      : orderLine.rejected_reason_type;
  }
  return orderLine.damage_state !== 'NONE'
    ? DEFAULT_REJECTED_REASON_TYPE[
        orderLine.damage_state as Exclude<DamageState, 'NONE'>
      ]
    : orderLine.rejected_reason_type;
};

export const transformSuccessPayload = (
  formValues: ToSuccessFormValues,
  deliveryId?: string,
  isNewIrregularityEnumEnabled?: boolean,
  isEnableGeoLocation?: boolean,
): ToSuccessTransformedFormValues => {
  const attachments = formValues.attachments?.filter(
    attachment => attachment.link,
  );
  const orderLines = formValues.order_lines.map(orderLine => {
    const rejectedQty =
      orderLine.damage_state !== 'NONE'
        ? orderLine.damaged_quantity - orderLine.receive_damaged_quantity
        : orderLine.rejected_quantity;

    const rejectedReasonType = generateRejectedReasonType(
      orderLine,
      isNewIrregularityEnumEnabled,
    );

    const receivedGoodQtySubtractor =
      orderLine.damage_state !== 'NONE'
        ? orderLine.damaged_quantity
        : orderLine.rejected_quantity;

    return {
      id: orderLine.id,
      receive_good_quantity:
        orderLine.carried_quantity - receivedGoodQtySubtractor,
      receive_damaged_quantity: orderLine.receive_damaged_quantity,
      final_good_product_price: orderLine.final_good_product_price,
      final_damaged_product_price: orderLine.final_damaged_product_price,
      rejected_quantity: Number(rejectedQty.toFixed(2)),
      rejected_reason_type: rejectedReasonType,
      rejected_reason_detail: orderLine.rejected_reason_detail,
    };
  });

  const payload = { attachments, order_lines: orderLines };

  if (isEnableGeoLocation) {
    const coords = geoFetchLocationFromStorage(deliveryId);
    return {
      ...payload,
      latitude: coords?.lat ?? null,
      longitude: coords?.lng ?? null,
    };
  }

  return payload;
};

export const validateAllRejected = (formValues: ToSuccessFormValues) => {
  return transformSuccessPayload(formValues).order_lines.every(orderLine => {
    return (
      orderLine.receive_good_quantity === 0 &&
      orderLine.receive_damaged_quantity === 0
    );
  });
};

export const handleAllowedErrors = (error: HttpError, action: () => void) => {
  if (
    error.message === FISH_INTERNAL_ERROR ||
    error.message === ORDER_AGGREGATOR_ERROR
  ) {
    action();
  }
};

export const errorNotification = (
  error: HttpError | undefined,
): OpenNotificationParams => {
  let description = error?.message;
  if (error) {
    handleAllowedErrors(error, () => {
      if (error.message === FISH_INTERNAL_ERROR) {
        description =
          'Status order terupdate. Namun, terdapat error di Fish Internal Tool Service';
      }
      if (error.message === ORDER_AGGREGATOR_ERROR) {
        description =
          'Status order terupdate. Namun, terdapat error di Order Aggregator';
      }
    });
  }
  return {
    type: 'error',
    message: 'Terjadi kesalahan',
    description,
  };
};
