import { Button, Center, Heading, Spinner, VStack } from '@efishery/onefish';
import { SignOut } from '@onefish/icons-react';
import { useGetIdentity } from '@refinedev/core';
import { Card, Description, GeoPopover } from 'components';
import { useGeoLocation } from 'hooks';
import { useHandleSignOut } from './hooks';
import { DriverProfile } from './types';
import { FLAG_ID } from 'configs';
import { GeoLocationSwitch } from './components';
import { flag } from '@onefish/flag';

export const ProfileList = () => {
  const profile = useGetIdentity<DriverProfile>();

  const handleSignOut = useHandleSignOut(profile);

  const { isShowPopover, onTogglePopover, isChecked, ...geoProps } =
    useGeoLocation({ pageName: 'profile' });

  if (!profile) {
    return (
      <Center h="100vh">
        <Spinner color="brand.500" data-testid="loading_spinner" />
      </Center>
    );
  }

  return (
    <VStack spacing={2} w="full" alignItems="stretch">
      <Card p="4" ml="auto" mr="auto" w={'75%'}>
        <Heading as="h2" size="md" mb={3} data-testid="txt_informasi_driver">
          Informasi Driver
        </Heading>
        <Description name="Nama driver" value={profile?.data?.name || '-'} />
        <Description
          name="Nomor HP"
          value={profile?.data?.phone_number || '-'}
        />
        <Description
          name="Jenis kendaraan"
          value={profile?.data?.fleet_name || '-'}
        />
        <Description
          name="Nomor kendaraan"
          value={profile?.data?.vehicle_license_plate || '-'}
        />
        <Description name="Vendor" value={profile?.data?.vendor_name || '-'} />
      </Card>

      {flag(
        FLAG_ID.ENABLE_FEAT_GEOLOCATION_FLAG_ID,
        <>
          <GeoLocationSwitch
            locationChangeHandler={onTogglePopover}
            isDisabled={isShowPopover}
            isChecked={isChecked}
          />
          <GeoPopover {...geoProps} />
        </>,
      )}

      <Center p="4">
        <Button
          data-testid="btn_signout"
          onClick={handleSignOut}
          variant="outline"
          leftIcon={<SignOut size="18" />}
        >
          Sign out
        </Button>
      </Center>
    </VStack>
  );
};
