import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@efishery/onefish';
import {
  BaseRecord,
  HttpError,
  useCreate,
  useInvalidate,
} from '@refinedev/core';
import {
  Address,
  Card,
  ComodityDescription,
  ConsignmentNoteButton,
  Description,
  GeoPopover,
} from 'components';
import { IrregularityEnumContext } from 'contexts';
import { useGeoLocation, useGetPhoneNumber, useRegenerateRR } from 'hooks';
import {
  geoRemoveLocationFromStorage,
  geoSavePosition,
} from 'hooks/useGeoLocation/helper';
import { FormEvent, useContext, useEffect, useRef, useState } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import type { Attachment, OrderDetail } from 'types';
import { trackReceivingReport } from 'utils';
import { getAttachmentLink } from 'utils/order';
import { DELIVERY_OPTIONS } from '../../../features/transport/constants';
import { formatShipDate } from '../../helpers';
import type {
  CancellationFormValues,
  OrderLinePayload,
  ToSuccessFormValues,
  ToSuccessTransformedFormValues,
} from '../../types';
import {
  composeDeliveryMessage,
  errorNotification,
  getOnDeliveryDefaultValues,
  handleAllowedErrors,
  transformSuccessPayload,
  validateAllRejected,
} from '../helpers';
import ActionToastDescription from './ActionToastDescription';
import { CancellationForm } from './CancellationForm';
import { FishConditionForm } from './FishConditionForm';
import { UnloadingUploadForm } from './UnloadingUploadForm';

type OnDeliveryProps = {
  deliveryDetail: OrderDetail;
  isEnableGeoLocation?: boolean;
};

export const OnDelivery = ({
  deliveryDetail,
  isEnableGeoLocation,
}: OnDeliveryProps) => {
  const isNewIrregularityEnumEnabled = useContext(IrregularityEnumContext);

  const invalidate = useInvalidate();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [receivingReport, setReceivingReport] = useState<Attachment>();
  const toast = useToast();

  const { mutate: finishOrder, isLoading: isFinishing } = useCreate<
    BaseRecord,
    HttpError,
    ToSuccessTransformedFormValues
  >();
  const { mutate: cancelOrder, isLoading: isCancelling } = useCreate<
    BaseRecord,
    HttpError,
    CancellationFormValues
  >();

  const finishForm = useForm<
    ToSuccessFormValues,
    HttpError,
    ToSuccessFormValues
  >({
    defaultValues: getOnDeliveryDefaultValues(deliveryDetail),
  });

  const cancellationForm = useForm<
    CancellationFormValues,
    HttpError,
    CancellationFormValues
  >({
    defaultValues: { remarks_type: undefined },
  });

  const { fields } = useFieldArray({
    name: 'order_lines',
    control: finishForm.control,
  });

  const updateToNextStatus: SubmitHandler<ToSuccessFormValues> = formValues => {
    const isAllRejected = validateAllRejected(formValues);

    if (isAllRejected) {
      if (!toast.isActive('rejected-toast')) {
        toast({
          id: 'rejected-toast',
          colorScheme: 'red',
          position: 'top-right',
          title: 'Barang tidak ada yang diterima',
          duration: null,
          isClosable: true,
          description: (
            <ActionToastDescription
              text="Ubah jumlah barang diterima atau batalkan pesanan"
              action="Batalkan"
              onClick={() => {
                onOpen();
                toast.closeAll();
              }}
            />
          ),
        });
      }
    } else {
      const resource =
        deliveryDetail?.shipment_type === 'LAST_MILE'
          ? `driver/shipping-order/${deliveryDetail.id}/status/payment`
          : `driver/shipping-order/${deliveryDetail.id}/status/delivery-success`;
      const values = transformSuccessPayload(
        formValues,
        deliveryDetail?.id,
        isNewIrregularityEnumEnabled,
        isEnableGeoLocation,
      );
      finishOrder(
        {
          resource,
          values,
          successNotification: {
            type: 'success',
            message: 'Berhasil update status order',
            description: `Status order ${deliveryDetail.so_number} menuju Pembayaran`,
          },
          errorNotification: errorNotification,
        },
        {
          onSuccess: () => {
            if (
              isEnableGeoLocation &&
              deliveryDetail?.shipment_type !== 'LAST_MILE'
            ) {
              geoRemoveLocationFromStorage(deliveryDetail?.id);
            }
            invalidate({
              resource: `driver/shipping-order`,
              invalidates: ['all'],
            });
          },
          onError: error => {
            handleAllowedErrors(error, () => {
              invalidate({
                resource: `driver/shipping-order`,
                invalidates: ['all'],
              });
            });
          },
        },
      );
    }
  };

  const submitCancellationForm: SubmitHandler<
    CancellationFormValues
  > = formValues => {
    cancelOrder(
      {
        resource: `driver/shipping-order/${deliveryDetail.id}/status/delivery-failed`,
        values: formValues,
        successNotification: {
          type: 'success',
          message: 'Berhasil membatalkan order',
          description: `Status order ${deliveryDetail.so_number} menjadi Pengiriman Gagal`,
        },
        errorNotification: errorNotification,
      },
      {
        onSuccess: () => {
          invalidate({
            resource: `driver/shipping-order`,
            invalidates: ['all'],
          });
        },
        onError: error => {
          handleAllowedErrors(error, () => {
            invalidate({
              resource: `driver/shipping-order`,
              invalidates: ['all'],
            });
          });
        },
      },
    );
  };

  const { mutate: generateRR, isLoading: isGenerateRRLoading } = useCreate<
    BaseRecord & Attachment,
    HttpError
  >();

  const { trigger, setValue, clearErrors, formState, watch, getValues } =
    finishForm;
  const { errors } = formState;

  const [isDisabledGenRR, setIsDisabledGenRR] = useState(true);

  const { shouldRegenerateRR, setShouldRegenerateRR, setGeneratedReceivedQty } =
    useRegenerateRR({
      control: finishForm.control,
      receivingReport,
    });

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      if ((name ?? '').indexOf('order_lines') > -1) {
        setIsDisabledGenRR(false);
      }
    });
    return () => subscription.unsubscribe();
  }, [errors, watch]);

  const phone = useGetPhoneNumber();
  const generateRRHandler = async () => {
    await trigger('order_lines');
    if (!errors.order_lines) {
      trackReceivingReport('confirm_receiving', {
        phone_number: phone,
      });
      generateRR(
        {
          resource: `driver/shipping-order/${deliveryDetail.id}/receiving-report`,
          values: {
            order_lines:
              transformSuccessPayload(getValues())?.order_lines ??
              ([] as OrderLinePayload[], isNewIrregularityEnumEnabled),
          },
          successNotification: {
            type: 'success',
            message: 'Berhasil generate RR',
          },
        },
        {
          onSuccess: resp => {
            setReceivingReport(resp?.data);
            setValue('attachments.0', {
              link: resp?.data?.link,
              type: 'RECEIVING_REPORT',
            });
            clearErrors('attachments.0.link');
          },
          onError: () => {
            setReceivingReport(undefined);
            setValue('attachments.0', {
              link: '',
              type: 'RECEIVING_REPORT',
            });
          },
        },
      );
    }
    setGeneratedReceivedQty(
      getValues('order_lines').map(orderLine => {
        return {
          receivedGoodQty: orderLine.receive_good_quantity,
          receivedDamagedQty: orderLine.receive_damaged_quantity,
          damageState: orderLine.damage_state,
        };
      }),
    );
    setShouldRegenerateRR(false);
  };

  const consignmentNote = getAttachmentLink(
    deliveryDetail.attachments,
    'CONSIGNMENT_NOTE',
  );

  const pageName = 'on-delivery';
  const {
    isShouldShowPopover,
    onTogglePopover,
    onClosePopover,
    isLocationActive,
    ...geoProps
  } = useGeoLocation({
    positionKey: deliveryDetail.id,
    pageName,
  });

  const _persistGeoToStorage = () => {
    navigator.geolocation.getCurrentPosition(position => {
      geoSavePosition(position.coords, deliveryDetail.id, pageName);
      finishForm.handleSubmit(updateToNextStatus)();
    });
  };

  const submitHandlerWithGeo = (e: FormEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (!isEnableGeoLocation) {
      finishForm.handleSubmit(updateToNextStatus)();
      return;
    }

    if (isShouldShowPopover()) {
      onTogglePopover();
      return;
    }

    if (document && navigator && isLocationActive()) {
      _persistGeoToStorage();
      return;
    }

    finishForm.handleSubmit(updateToNextStatus)();
  };

  return (
    <>
      <FormProvider {...finishForm}>
        <VStack
          w="full"
          alignItems="stretch"
          spacing="3"
          mb="6"
          as="form"
          onSubmit={submitHandlerWithGeo}
          key="1"
        >
          <Card p="4">
            <VStack w="full" alignItems="stretch" spacing="3">
              <Heading as="h2" size="md">
                Bongkar Barang
              </Heading>

              <Flex bg="brand.70" borderRadius="md" p="3" color="brand.600">
                <Box w={consignmentNote ? '80%' : '100%'}>
                  <Text fontWeight="semibold">{deliveryDetail.dest_name}</Text>
                </Box>
                <ConsignmentNoteButton link={consignmentNote} />
              </Flex>

              <Text fontSize="md" lineHeight="5" color="grey.500">
                {composeDeliveryMessage(deliveryDetail)}
              </Text>
            </VStack>
          </Card>

          <Address
            address={deliveryDetail.dest_address}
            city={deliveryDetail.dest_city_name}
            province={deliveryDetail.dest_province_name}
          />

          <Card p="4">
            <Description
              name="Tanggal pengantaran"
              value={formatShipDate(deliveryDetail.delivery_at)}
            />
          </Card>

          {fields?.map((orderLine, index) => {
            return (
              <Card p="4" key={index}>
                <VStack w="full" alignItems="stretch" spacing="2">
                  <ComodityDescription
                    name="Jenis"
                    businessType={deliveryDetail?.business_type}
                    value={deliveryDetail.order_lines[index].product_name}
                  />
                  <ComodityDescription
                    name="Ukuran"
                    businessType={deliveryDetail?.business_type}
                    value={deliveryDetail.order_lines[index].product_name}
                  />

                  <Divider variant="dashed" />

                  <Description
                    name="Jumlah yang Dibawa"
                    value={orderLine.carried_quantity}
                    format="thousand-separator"
                    unit="kg"
                  />

                  <Divider variant="dashed" />

                  <Description
                    name="Harga"
                    value={new Intl.NumberFormat('id-ID', {
                      style: 'currency',
                      currency: 'IDR',
                      minimumFractionDigits: 2,
                    }).format(deliveryDetail.order_lines[index].original_price)}
                  />

                  <Description
                    name="Total"
                    value={new Intl.NumberFormat('id-ID', {
                      style: 'currency',
                      currency: 'IDR',
                      minimumFractionDigits: 2,
                    }).format(
                      deliveryDetail.order_lines[index].total_original_price,
                    )}
                  />

                  <Divider variant="dashed" />

                  <FishConditionForm
                    deliveryDetail={deliveryDetail}
                    orderLine={deliveryDetail.order_lines[index]}
                    index={index}
                  />
                </VStack>
              </Card>
            );
          })}

          <Card p="4">
            <Description
              name="Grand Total"
              value={new Intl.NumberFormat('id-ID', {
                style: 'currency',
                currency: 'IDR',
                minimumFractionDigits: 2,
              }).format(deliveryDetail.total_original_price_nett)}
            />
          </Card>

          <Card p="4">
            <Button
              size={'md'}
              variant="solid"
              width={'full'}
              onClick={() => generateRRHandler()}
              data-testid="btn_generate_rr"
              isLoading={isGenerateRRLoading}
              isDisabled={isDisabledGenRR || Boolean(errors.order_lines)}
            >
              Konfirmasi {shouldRegenerateRR && 'Ulang'} Jumlah Diterima
            </Button>
          </Card>

          <UnloadingUploadForm
            receivingReport={receivingReport}
            isGenerateRRLoading={isGenerateRRLoading}
          />

          <Button
            width="full"
            variant="ghost"
            colorScheme="red"
            onClick={onOpen}
            isLoading={isCancelling}
            loadingText="Membatalkan pesanan..."
            ref={buttonRef}
          >
            Pesanan Batal
          </Button>
          <Button
            width="full"
            type="submit"
            isLoading={isFinishing}
            isDisabled={!receivingReport || shouldRegenerateRR}
            loadingText="Memperbarui status..."
            data-testid="btn_next_status"
          >
            {deliveryDetail?.shipment_type === 'LAST_MILE'
              ? 'Menuju Pembayaran'
              : DELIVERY_OPTIONS['DELIVERY_SUCCESS'].label}
          </Button>
        </VStack>
      </FormProvider>

      <FormProvider {...cancellationForm}>
        <Drawer
          isOpen={isOpen}
          placement="bottom"
          onClose={() => {
            onClose();
            cancellationForm.reset();
          }}
          finalFocusRef={buttonRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <VStack
              w="full"
              maxW="md"
              marginX="auto"
              position="relative"
              align="stretch"
              as="form"
              onSubmit={cancellationForm.handleSubmit(submitCancellationForm)}
              key="2"
            >
              <DrawerCloseButton />
              <DrawerHeader>Pembatalan Pesanan</DrawerHeader>

              <DrawerBody>
                <CancellationForm />
              </DrawerBody>

              <DrawerFooter>
                <Button
                  type="submit"
                  width="full"
                  bg="red.700"
                  _hover={{ bgColor: 'red.600' }}
                >
                  Konfirmasi Pembatalan
                </Button>
              </DrawerFooter>
            </VStack>
          </DrawerContent>
        </Drawer>
      </FormProvider>

      {isEnableGeoLocation && (
        <GeoPopover
          {...geoProps}
          yesHandler={() => {
            _persistGeoToStorage();
            onClosePopover();
          }}
        />
      )}
    </>
  );
};
