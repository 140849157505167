/* eslint-disable react-hooks/exhaustive-deps */
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  NumberInput,
  NumberInputField,
  Select,
  Textarea,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { OrderDetail, OrderLine } from 'types';
import { REJECT_REASONS } from '../../../features/transport/constants';
import { ToSuccessFormValues } from '../../types';

type RejectionFormProps = {
  deliveryDetail: OrderDetail;
  orderLine: OrderLine;
  index: number;
  'data-testid-prefix'?: string;
};

export const RejectionForm = ({
  index,
  orderLine,
  'data-testid-prefix': dataTestIdPrefix = '',
}: RejectionFormProps) => {
  const {
    register,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<ToSuccessFormValues>();

  const watchRemarksType = watch(`order_lines.${index}.rejected_reason_type`);
  const watchReceivedGoodQty = watch(
    `order_lines.${index}.receive_good_quantity`,
  );
  const watchRejectedQty = watch(`order_lines.${index}.rejected_quantity`);

  useEffect(() => {
    const receivedQty = isNaN(watchReceivedGoodQty)
      ? orderLine.carried_quantity
      : watchReceivedGoodQty;

    const rejectedQty = orderLine.carried_quantity - receivedQty;

    setValue(
      `order_lines.${index}.rejected_quantity`,
      Number(rejectedQty.toFixed(2)),
    );
  }, [
    watchReceivedGoodQty,
    errors?.order_lines?.[index]?.receive_good_quantity,
  ]);

  return (
    <>
      <FormControl
        isInvalid={Boolean(errors?.order_lines?.[index]?.receive_good_quantity)}
      >
        <FormLabel
          fontSize="sm"
          data-testid={`${dataTestIdPrefix}receive_good_quantity_label`}
        >
          Jumlah diterima
        </FormLabel>
        <InputGroup>
          <NumberInput w="full">
            <NumberInputField
              borderEndRadius="none"
              {...register(`order_lines.${index}.receive_good_quantity`, {
                required: 'Tidak boleh kosong!',
                max: {
                  value: Number(orderLine.carried_quantity),
                  message: 'Tidak boleh melebihi jumlah total yang dibawa',
                },
                min: {
                  value: 0.1,
                  message: 'Jumlah diterima tidak valid',
                },
                valueAsNumber: true,
                onBlur: () => {
                  trigger(`order_lines.${index}.receive_good_quantity`);
                },
              })}
            />
          </NumberInput>
          <InputRightAddon children="kg" />
        </InputGroup>
        <FormErrorMessage>
          {errors?.order_lines?.[index]?.receive_good_quantity?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={Boolean(errors?.order_lines?.[index]?.rejected_quantity)}
      >
        <FormLabel fontSize="sm">Jumlah ditolak</FormLabel>
        <InputGroup>
          <Input
            disabled
            {...register(`order_lines.${index}.rejected_quantity`, {
              valueAsNumber: true,
              onBlur: () => {
                trigger(`order_lines.${index}.rejected_quantity`);
              },
            })}
            style={{ color: 'black' }}
          />
          <InputRightAddon children="kg" />
        </InputGroup>
        <FormErrorMessage>
          {errors?.order_lines?.[index]?.rejected_quantity?.message}
        </FormErrorMessage>
      </FormControl>

      {watchRejectedQty && watchRejectedQty > 0 ? (
        <FormControl
          my="2"
          isInvalid={Boolean(
            errors?.order_lines?.[index]?.rejected_reason_type,
          )}
        >
          <FormLabel fontSize="sm">Alasan</FormLabel>
          <Select
            placeholder="Pilih alasan"
            {...register(`order_lines.${index}.rejected_reason_type`, {
              required: 'Alasan wajib dipilih',
              onChange: () => {
                trigger(`order_lines.${index}.rejected_reason_type`);
              },
            })}
          >
            {REJECT_REASONS.map(reason => (
              <option key={reason.value} value={reason.value}>
                {reason.label}
              </option>
            ))}
          </Select>
          <FormErrorMessage>
            {errors?.order_lines?.[index]?.rejected_reason_type?.message}
          </FormErrorMessage>
        </FormControl>
      ) : null}

      {watchRemarksType === 'DEFECT_OTHER' && (
        <FormControl
          my="2"
          isInvalid={Boolean(
            errors?.order_lines?.[index]?.rejected_reason_detail,
          )}
        >
          <FormLabel fontSize="sm">Alasan lainnya</FormLabel>
          <Textarea
            {...register(`order_lines.${index}.rejected_reason_detail`, {
              required: 'Alasan lainnya wajib diisi',
              onBlur: () => {
                trigger(`order_lines.${index}.rejected_reason_detail`);
              },
            })}
          />
          <FormErrorMessage>
            {errors?.order_lines?.[index]?.rejected_reason_detail?.message}
          </FormErrorMessage>
        </FormControl>
      )}
    </>
  );
};
