import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputRightAddon,
  NumberInput,
  NumberInputField,
  Select,
  VStack,
} from '@efishery/onefish';
import { MinusCircle } from '@onefish/icons-react';
import { useSelect } from '@refinedev/core';
import { useFormContext } from 'react-hook-form';

import type { BusinessType, Product } from 'types';
import type { ToIntransitFormValues } from '../../../types';
import { comodity } from 'features/transport/utils';

type InputFieldsProps = {
  index: number;
  onRemove: () => void;
  businessTypeQuery?: string;
};

type ProductOption = {
  product_code: string;
  product_name: string;
  product_uom: string;
  weight_in_kg: number;
};

export const InputFields = ({
  index,
  onRemove,
  businessTypeQuery = 'FISH_DOWNSTREAM',
}: InputFieldsProps) => {
  const {
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<ToIntransitFormValues>();

  const { queryResult } = useSelect<Product>({
    resource: 'products',
    filters: [
      {
        field: 'limit',
        operator: 'eq',
        value: 999,
      },
      {
        field: 'business_type',
        operator: 'eq',
        value: businessTypeQuery,
      },
    ],
  });

  const options: ProductOption[] = (queryResult.data?.data ?? []).map(item => ({
    product_code: item.code,
    product_name: item.name,
    product_uom: item.uom,
    weight_in_kg: 1,
  }));

  const onProductSelect = (productCode: string) => {
    const selectedProduct = options.find(
      option => option.product_code === productCode,
    );

    if (selectedProduct) {
      setValue(
        `additional_order_lines.${index}.product_name`,
        selectedProduct?.product_name,
      );
      setValue(
        `additional_order_lines.${index}.product_uom`,
        selectedProduct?.product_uom,
      );
      setValue(
        `additional_order_lines.${index}.weight_in_kg`,
        selectedProduct?.weight_in_kg,
      );
    }
  };

  return (
    <Box p="4" bg={index % 2 ? 'white' : 'brand.70'} borderRadius="md" mb="4">
      <VStack w="full" alignItems="stretch" spacing="3">
        <FormControl isRequired>
          <Flex justifyContent="space-between" alignItems="baseline">
            <FormLabel fontSize="sm">
              {'Jenis ' +
                comodity(
                  (businessTypeQuery as BusinessType) ?? 'FISH_DOWNSTREAM',
                )}
            </FormLabel>
            <Box
              data-testid="btn_input_fields_remove"
              as="button"
              color="red.600"
              onClick={onRemove}
            >
              <MinusCircle />
            </Box>
          </Flex>
          <Select
            placeholder="Pilih"
            {...register(`additional_order_lines.${index}.product_code`)}
            onChange={e => onProductSelect(e.target.value)}
          >
            {options?.map((option, i) => (
              <option
                key={`${option.product_code}-${index}-${i}`}
                value={option.product_code}
              >
                {option.product_name}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl
          isRequired
          isInvalid={Boolean(
            errors?.additional_order_lines?.[index]?.carried_quantity,
          )}
        >
          <FormLabel fontSize="sm">Jumlah yang Dibawa</FormLabel>
          <InputGroup>
            <NumberInput w="full" precision={2}>
              <NumberInputField
                borderEndRadius="none"
                {...register(
                  `additional_order_lines.${index}.carried_quantity`,
                  {
                    valueAsNumber: true,
                    min: {
                      value: 0.1,
                      message: 'Jumlah yang dibawa wajib diisi',
                    },
                    onBlur: () => {
                      trigger(
                        `additional_order_lines.${index}.carried_quantity`,
                      );
                    },
                  },
                )}
              />
            </NumberInput>
            <InputRightAddon children="kg" />
          </InputGroup>
          <FormErrorMessage>
            {errors?.additional_order_lines?.[index]?.carried_quantity?.message}
          </FormErrorMessage>
        </FormControl>
      </VStack>
    </Box>
  );
};
