/* eslint-disable @typescript-eslint/no-explicit-any */
import { SubmitHandler } from 'react-hook-form';
import type { OrderDetail } from 'types';

import {
  BaseRecord,
  HttpError,
  useCreate,
  useInvalidate,
} from '@refinedev/core';

import { useEffect, useState } from 'react';
import type { ToIntransitFormValues } from '../../../types';
import { onErrorCallback } from './helpers';

export const useDeliveryLoading = (
  deliveryDetail: OrderDetail,
  watchSuratJalanInput?: string,
  suratJalan?: string,
) => {
  const { mutate, isLoading } = useCreate<
    BaseRecord,
    HttpError,
    ToIntransitFormValues
  >();

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    if (watchSuratJalanInput) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [watchSuratJalanInput]);

  useEffect(() => {
    if (suratJalan) {
      setIsSubmitDisabled(true);
    }
  }, [suratJalan]);

  const invalidate = useInvalidate();

  const updateToNextStatus: SubmitHandler<
    ToIntransitFormValues
  > = formValues => {
    mutate(
      {
        resource: `driver/shipping-order/${deliveryDetail.id}/status/intransit`,
        values: formValues,
        successNotification: {
          type: 'success',
          message: 'Berhasil update status order',
          description: `Status order ${deliveryDetail.so_number} menjadi Lanjut Kirim Barang`,
        },
      },
      {
        onSuccess: () => {
          invalidate({
            resource: `driver/shipping-order`,
            invalidates: ['all'],
          });
        },
        onError: error => onErrorCallback(error, invalidate),
      },
    );
  };

  const confirmationButtonHandler = () => {
    setIsSubmitDisabled(false);
  };

  return {
    updateToNextStatus,
    isLoading,
    isSubmitDisabled,
    setIsSubmitDisabled,
    confirmationButtonHandler,
  };
};
