import dayjs from 'dayjs';
import { AttachmentType, OrderDetail } from 'types';
import { getAttachmentLink } from 'utils/order';
import { ToIntransitFormValues } from './types';

export const getLoadingDefaultValues = (
  deliveryDetail: OrderDetail,
): ToIntransitFormValues => {
  const consignmentNote =
    getAttachmentLink(deliveryDetail.attachments, 'CONSIGNMENT_NOTE') ?? '';
  const attachments = !consignmentNote
    ? {
        attachments: [
          {
            link: consignmentNote,
            type: 'CONSIGNMENT_NOTE' as AttachmentType,
          },
        ],
      }
    : {};

  return {
    ...attachments,
    order_lines: deliveryDetail.order_lines.map(orderLine => ({
      id: orderLine.id,
      carried_quantity: orderLine.quantity,
    })),
  };
};

export const formatShipDate = (ship_date?: string, format = 'D MMM YYYY') => {
  return ship_date ? dayjs.utc(ship_date).local().format(format) : '';
};

export const formatDateTimeFull = (
  ship_date?: string,
  format = 'D MMM YYYY, HH:mm WIB',
) => {
  return ship_date ? dayjs.utc(ship_date).local().format(format) : '';
};
