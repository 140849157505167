import { SelectProps } from '@chakra-ui/react';
import { SyncStatus } from 'pages/delivery/components/OnInvoicePayment/types';
import { ChangeEvent, useEffect, useState } from 'react';
import { BusinessType, OrderDetail, PaymentType } from 'types';
import { MetodePembayaranOpts, isAbleToSelectPayment } from './helper';

export const useMetodePembayaran = (
  businessType: BusinessType,
  syncStatus?: SyncStatus,
  isEnableFeatHardCash?: boolean,
  isFeatShrimpDownstream?: boolean,
  deliveryDetail?: OrderDetail,
) => {
  const [paymentOpt, setPaymentOpt] = useState<PaymentType | undefined>();

  const metodePembayaranChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    const paymentType = e.target.value as PaymentType;
    setPaymentOpt(paymentType);
  };

  const metodePembayaranProps: SelectProps = {
    onChange: metodePembayaranChangeHandler,
    children: MetodePembayaranOpts.map(opt => (
      <option
        key={opt.value}
        value={opt.value}
        data-testid={opt['data-testid']}
        disabled={
          !isAbleToSelectPayment(
            opt.value,
            businessType,
            syncStatus,
            isEnableFeatHardCash,
            isFeatShrimpDownstream,
          )
        }
      >
        {opt.label}
      </option>
    )),
  };

  // automatically select HARD_CASH
  useEffect(() => {
    const isSkipAutoSelectHardCash =
      isFeatShrimpDownstream && businessType === 'SHRIMP_DOWNSTREAM';
    if (
      !isSkipAutoSelectHardCash &&
      deliveryDetail?.payment_type === 'HARD_CASH' &&
      isEnableFeatHardCash &&
      document &&
      document.getElementById('dd_payment_type')
    ) {
      (document.getElementById('dd_payment_type') as HTMLInputElement).value =
        'HARD_CASH';
      setPaymentOpt('HARD_CASH');
    }
  }, [
    businessType,
    deliveryDetail?.payment_type,
    isEnableFeatHardCash,
    isFeatShrimpDownstream,
  ]);

  return {
    paymentOpt,
    metodePembayaranProps,
  };
};
