import { Center, Heading, Image, Text, VStack } from '@efishery/onefish';
import { FLAG_ID } from 'configs';
import { useFlagData } from 'hooks';
import { ReactNode } from 'react';

type MaintenanceProps = {
  children?: ReactNode;
};

const Maintenance = ({ children }: MaintenanceProps) => {
  const flagData = useFlagData(FLAG_ID.ENABLE_MAINTENANCE_DRIVER_APP);
  const isOn = flagData?.variantKey === 'on';
  const attachment = flagData?.variantAttachment;

  const title =
    attachment !== undefined && 'title' in attachment
      ? attachment.title
      : 'Maaf, Sedang Ada Pemeliharaan di Sistem Kami.';

  const description =
    attachment !== undefined && 'description' in attachment
      ? attachment.description
      : 'Harap tunggu sebentar ya.';

  if (isOn) {
    return (
      <Center h="100vh">
        <VStack spacing="4">
          <Heading as="h1" size="xl" mb="3">
            Maintenance
          </Heading>
          <Image src="/maintenance.png" alt="Maintenance" />
          <Text>{String(title)}</Text>
          <Text>{String(description)}</Text>
        </VStack>
      </Center>
    );
  }

  return children;
};

export default Maintenance;
